import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Button
} from "@mui/material";
import { menubarIcon, katorlogo, fullsolidcircle, fullwaveimage, halfwaveimage, arrowForwardIcon, footerkatorlogo, footerArrForwIcon, dotGridImage, verticalHalfCircle, dotGrid5x6Image, iphone, halfsolidcircle } from "./assets";
import { useNavigate } from "react-router-dom";
import Contactus from "../../contactus/src/Contactus.web";
import NavigationMenuWeb from "../../navigationmenu/src/NavigationMenu.web";

// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";

export default class LandingPageWeb extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <Box sx={styles.mainContainer}>
        <Box>
          <NavigationMenuWeb      
            navigation={this.props.navigation}
            id={this.props.id}
            showHeaderOnly={true}
            isLandingPageShowLogo={true}
          />
        </Box>
        <Box sx={styles.logoContainer}>
          <Box component="img" sx={styles.katorlogo} src={katorlogo} alt="kator" />
        </Box>
        <Box sx={styles.firstdesignpatterncontainer}>
          <Box sx={styles.firstdesignpattern}>
            <Box component="img" sx={styles.dotgrid6X6} src={dotGridImage} alt="dotgrid6x6" />
            <Box component="img" sx={styles.fullcircle} src={fullsolidcircle} alt="fullcircle" />
            <Box component="img" sx={styles.halfwaveImage} src={halfwaveimage} alt="halfwaveimage" />
          </Box>
        </Box>
        <Box sx={styles.desktopTitleContainer}>
          <Box sx={styles.titleContainer}>
            <Box component="span" sx={styles.title}>{configJSON.landingPageTitle}</Box>
            <Box component="span" sx={styles.subtitle}>{configJSON.landingPageSubtitle}</Box>
            <Box component="span" sx={styles.subtitle2}>{configJSON.landingPageSubtitle2}</Box>

            <Box sx={styles.secondbagImageContainer}>
              <Box component="img" sx={styles.dotgrid5X6} src={dotGrid5x6Image} alt="dotgrid" />
              <Box sx={styles.btnContainer}>
                <Button data-testid="begin-btn" onClick={() => this.handleNavigateZipCode()} sx={styles.textBtn1}>
                  <Typography sx={styles.btnText}>{configJSON.Begin}</Typography>
                  <Box component="img" sx={styles.forwArrIcon} src={arrowForwardIcon} alt="rightArrow" />
                  <Box component="img" sx={styles.deskforwArrIcon} src={footerArrForwIcon} alt="rightArrow" />
                </Button>
              </Box>
            </Box>
          </Box>
          <Box component="img" sx={styles.iphone} src={iphone} alt="iphone" />
        </Box>
        <Box sx={styles.aboutTitleContainer} id="aboutUs">
          <Box component="span" sx={styles.aboutTitle}>{configJSON.aboutus}</Box>
          <Box component="span" sx={styles.aboutSubtitle}>
          We are an experimental product designed to improve access to information for local elections. While we do our best to eliminate errors, due to the use of generative and experimental AI, <Box component="span" sx={styles.aboutHighlightedSubtitle} >
          mistakes can occur </Box> . You should not rely solely on this product for information on local elections and should always conduct your own independent research. Consider this product a proof of concept or a starting point.
          </Box>
          <Box sx={styles.desktopAboutImgs}>
            <Box component="img" sx={styles.desktopDotgrid6X6} src={dotGridImage} alt="dotgrid6x6" />
            <Box component="img" sx={styles.desktopFullcircle} src={halfsolidcircle} alt="fullcircle" />
          </Box>
        </Box>
        <Box sx={{ ...styles.btnContainer, margin: "20px 16px" }}>
          <Button data-testid="startTestID" sx={styles.StartTextBtn}>
          <Typography sx={styles.btnText}>{configJSON.Start}</Typography>
            <Box component="img" sx={styles.forwArrIcon} src={arrowForwardIcon} alt="rightArrow" />
          </Button>
        </Box>
        <Box id="contactUs">
          <Contactus 
              navigation={this.props.navigation}
              id={this.props.id}
          />
        </Box>
        <NavigationMenuWeb      
          navigation={this.props.navigation}
          id={this.props.id}
          showFooterOnly={true}
          isLandingPageShowLogo={true}
        />
      </Box >
    );
  }
}

// Customizable Area Start

const styles = {
  mainContainer: {
    backgroundColor: "#F1ECE4",
    position: "relative" as const,
    "@media (max-width: 575px)": {
      width: "100%",
    },
  },
  nav: {
    display: "none",
    "@media (max-width: 575px)": {
      backgroundColor: "transparent",
      height: "48px",
      alignItems: "center",
      margin: "0px 0px 0px 8px",
      display: "flex",
    },
  },
  navButtonContainer: {
    marginLeft: "20rem",
    marginTop: "1.5rem",
    gap: "7.5rem",
    display: "flex",
    "@media (max-width: 575px)": {
      display: "none",
    },
  },
  navButton: {
    textTransform: "inherit",
    color: "#948E8D",
    padding: "10px 16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#524948",
    },
  },
  navBtnTypograpy: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    textAlign: "left",
  },
  logoContainer: {
    display: "none",
    "@media (max-width: 767px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      margin: "4rem 0 1.5rem 0",
    },
  },
  katorlogo: {
    width: "150px",
    height: "50px",

    "@media (max-width: 575px)": {
      width: "108px",
      height: "33px",
    },
  },
  firstdesignpatterncontainer: {
    "@media (min-width: 767px)": {
      display: "none",
    },
  },
  firstdesignpattern: {
    height: "12.5rem",
    margin: "2rem 0rem",
    "@media (max-width: 767px)": {
      position: "relative" as const,
    }
  },
  dotgrid6X6: {
    position: "absolute" as const,
    width: "6.25rem",
    height: "6.25rem",
    right: "16rem",
    top: "6.5rem",
    zIndex: "1",
  },
  fullcircle: {
    position: "absolute" as const,
    right: "10rem",
    top: "1rem",
    width: "10.25rem",
    height: "10.25rem",
  },
  halfwaveImage: {
    position: "absolute" as const,
    right: "0rem",
    top: "1rem",
    width: "15rem",
    height: "10rem",
    "@media (max-width: 575px)": {
      top: "2rem",
    },
  },
  desktopTitleContainer : {
    display: "flex",
    justifyContent: "space-between",
    margin: "1.5rem 1rem 2rem 0rem",
    "@media (max-width: 575px)": {
      margin: "0rem",
    },
  },
  iphone :{
    width: "47vw",
    height: "auto",
    "@media (max-width: 767px)": {
      display: "none",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      display: "none",
    },
  },
  titleContainer: {    
    width: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "8px",
    justifyContent: "center",
    alignItems: "start",
    padding: "8px 0px",
    margin: "0rem",
    "@media (min-width: 576px)": {
      margin: "0rem",
      width: "100vw",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      margin: "0rem",
      width: "100vw",
    },
  },
  title: {
    "@media (min-width: 576px)": {
      color: "#524948",
      fontFamily: "Inter",
      fontSize: "48px",
      fontWeight: "700",
      lineHeight: "56px",
      letterSpacing: "-0.015em",
      textAlign: "left" as "left",
      marginLeft: "5rem",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      color: "#524948",
      fontFamily: "Inter",
      fontSize: "35px",
      fontWeight: "700",
      lineHeight: "56px",
      letterSpacing: "-0.015em",
      textAlign: "left" as "left",
      marginLeft: "5rem",
    },
    color: "#524948",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: "700",
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left" as "left",
    margin: "0rem 1.5rem 0rem",
  },
  subtitle: {
    "@media (min-width: 576px)": {
      color: "#706968",
      fontFamily: "Inter",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "28px",
      textAlign: "left" as "left",
      marginLeft: "5rem",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      color: "#706968",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "28px",
      textAlign: "left" as "left",
      marginLeft: "5rem",
    },
    color: "#706968",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
    margin: "0rem 1.5rem 0rem",
  },
  subtitle2: {
    "@media (max-width: 575px)": {
      display: "none",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      fontSize: "18px",
    },
    color: "#706968",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "28px",
    textAlign: "left" as "left",
    marginLeft: "5rem",
  },
  aboutTitleContainer: {    
    display: "flex",
    flexDirection: "column" as "column",
    gap: "8px",
    justifyContent: "center",
    alignItems: "start",
    padding: "3rem 5rem",
    position: "relative" as const,
    "@media (max-width: 575px)": {
      position: "static" as const,
      margin: "0rem",
      padding: "0.5rem 1.5rem",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      position: "static" as const,
      margin: "0rem",
      padding: "3rem 5rem",
    },
  },
  aboutTitle: {
    color: "#524948",
    fontFamily: "Inter",
    fontSize: "48px",
    fontWeight: "700",
    lineHeight: "56px",
    letterSpacing: "-0.015em",
    textAlign: "left" as "left",
    margin: "0rem",
    "@media (max-width: 575px)": {
      color: "#524948",
      fontFamily: "Inter",
      fontSize: "30px",
      fontWeight: "700",
      lineHeight: "40px",
      letterSpacing: "-0.005em",
      textAlign: "left" as "left",
      margin: "0rem",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      color: "#524948",
      fontFamily: "Inter",
      fontSize: "30px",
      fontWeight: "700",
      lineHeight: "40px",
      letterSpacing: "-0.005em",
      textAlign: "left" as "left",
      margin: "0rem",
    }
  },
  aboutSubtitle: {
    lineHeight: "28px",
    color: "#706968",
    fontSize: "20px",
    fontWeight: "400",
    textAlign: "left" as "left",
    margin: "0rem",
    fontFamily: "Inter",
    "@media (min-width: 768px) and (max-width: 1024px)": {
      fontWeight: "400",
      lineHeight: "24px",
      textAlign: "left" as "left",
      color: "#706968",
      fontFamily: "Inter",
      fontSize: "16px",
      margin: "0rem",
    },
    "@media (max-width: 575px)": {
      lineHeight: "24px",
      textAlign: "left" as "left",
      margin: "0rem",
      color: "#706968",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: "400",
    }    
  },
  aboutHighlightedSubtitle: {
    color: "#706968",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "28px",
    textAlign: "left" as "left",
    margin: "0rem",
    "@media (max-width: 575px)": {
      color: "#706968",
      fontFamily: "Inter",
      lineHeight: "24px",
      textAlign: "left" as "left",
      margin: "0rem",
      fontSize: "16px",
      fontWeight: "700",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      color: "#706968",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "24px",
      textAlign: "left" as "left",
      margin: "0rem",
    }
  },
  desktopAboutImgs: {
    position: "absolute" as const,
    top: "2rem",
	  right: "0rem",
    "@media (max-width: 575px)": {
      display: "none",
    },
  },
  desktopDotgrid6X6: {
    position: "absolute",
	  right: "0rem",
	  top: "3rem",
	  zIndex: "1",
    width: "5rem",
  },
  desktopFullcircle: {
    position: "absolute",
	  right: "0rem",
    height: "12rem",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
    margin: "0px 16px",
    "@media (min-width: 768px) and (max-width: 1024px)": {
      height: "fit-content",
      margin: "0rem 0rem 0rem 1rem",
    },
    "@media (min-width: 576px)": {
      margin: "0rem 0rem 0rem 1rem",
      height: "fit-content",
    },
    
  },
  textBtn1: {
    textTransform: "inherit",
    display: "flex",
    alignItems: "center",
    gap: "24px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#524948",
    },
    "@media (min-width: 576px)": {
      width: "256px",
      backgroundColor: "#6699B1",
      borderRadius: "0.5rem",
	    padding: "0.6rem 1rem",
	    cursor: "pointer",
	    color: "#F1ECE4",
      gap: "0.5rem",
      "&:hover": {
        backgroundColor: "#6699B1",
        color: "#F1ECE4",
      },
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      width: "256px",
      backgroundColor: "transparent",
      borderRadius: "0.5rem",
	    padding: "0.6rem 1rem",
	    cursor: "pointer",
	    color: "#524948",
      gap: "0.5rem",
      "&:hover": {
        backgroundColor: "transparent",
        color: "#524948",
      },
    },
  },
  StartTextBtn: {
    textTransform: "inherit",
    display: "flex",
    alignItems: "center",
    gap: "24px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#524948",
    },
    "@media (min-width: 576px)": {
      display: "none",
    },
  },
  btnText: {
    color: "#524948",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    textAlign: "left" as "left",
    "@media (min-width: 576px)": {
      color: "#F1ECE4",
      fontFamily: "Inter",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "28px",
      textAlign: "left" as "left",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      color: "#524948",
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "28px",
      textAlign: "left" as "left",
    },
  },
  contactBtnContainer: {
    display: "flex",
    justifyContent: "end",
    margin: "0px",
    "@media (min-width: 576px)": {
      margin: "0rem 0rem 0rem 0rem"
    },
  },
  contactBtnText: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    textAlign: "left" as "left",
    "@media (min-width: 576px)": {
      color: "#F1ECE4",
      fontFamily: "Inter",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "28px",
      textAlign: "left" as "left",
    },
  },
  secondbagImageContainer: {
    display: "flex",
    justifyContent: "flex-start",
    position: "relative" as const,
    marginTop: "1rem",
    "@media (max-width: 575px)": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      marginTop: "3rem",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      marginTop: "3rem",
    },
    
  },
  dotgrid5X6: {
    width: "6.25rem",
    height: "6.25rem",
    "@media (min-width: 576px)": {
      marginLeft: "-2rem",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      marginLeft: "-2rem",
    },
  },
  verticalHalfCircle: {

  },
  forwArrIcon: {
    display: "none",
    "@media (max-width: 575px)": {
      display: "inherit",
      width: "1.5rem",
      height: "1.5rem",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      display: "inherit",
      width: "1rem",
      height: "1rem",
    },
  },
  footerArrForwIcon: {
    width: "1.5rem",
    height: "1.5rem",
    "@media (min-width: 576px)": {
      width: "1rem",
      height: "1rem",
    },
  },
  deskforwArrIcon : {
    display: "inherit",
    width: "1rem",
    height: "1rem",
    "@media (max-width: 575px)": {
      display: "none",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      display: "none",
    },
  },
  contactFormWrapper: {
    position: "relative" as const,
    width: "100%",
    height: "auto",
    marginTop: "5rem"
  },
  fullwaveImageBehindForm: {
    position: "absolute" as const,
    "@media (max-width: 575px )": {
      width: "50%",
      height: "auto",
      top: "-3rem",
      left: "0",
    },
    width: "auto",
    left: "-5rem",
    top: "1.5rem",
  },
  contactFormContainer: {
    "@media (max-width: 575px)": {
      margin: "0rem",
    },
    backgroundColor: "#706968",
    borderRadius: "40px 40px 0px 0px",
    margin: "0rem 9rem",
    padding: "50px 0px",
    position: "relative" as const,
    zIndex: "1",
  },
  formTitleContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "start",
    gap: "8px",
    padding: "8px 24px",
  },
  formtitle: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: "700",
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left" as "left",
  },
  formSubtitle: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
    margin: "0px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "24px",
    padding: "24px 24px 0px",
  },
  groupInputWithLabel: {
    display: "flex",
    flex: "1",
    minWidth: "100%",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    gap: "4px",
  },
  textlabel: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "22px",
    textAlign: "left" as "left",
    // margin: "0px",
  },
  textinput: {
    width: "100%",
    height: "44px",
    padding: "10px 8px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #F1ECE4",
    outline: "none",
    opacity: "0px",
    backgroundColor: "transparent",
    color: "#F1ECE4B2",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
  },
  textareainput: {
    width: "100%",
    height: "100px",
    padding: "10px 8px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #F1ECE4",
    outline: "none",
    opacity: "0px",
    backgroundColor: "transparent",
    color: "#F1ECE4B2",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
  },
  descriptionTextLength: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    textAlign: "right" as "right",
  }
};
// Customizable Area End
